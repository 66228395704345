<!--账号设置-->
<template>
  <div style="padding: 20px;">
    <div>
      <div style="display: flex;align-items: center;justify-content: space-between;">
        <span style="font-size: 24px;font-weight: 500;color: #707070;">账号设置</span>
        <el-button style="background: #E4E5E9!important;border-color: #E4E5E9!important;color: #3F4157!important;" @click="returnPage()">返回</el-button>
      </div>
    </div>

    <div style="width: 80%;margin: auto;margin-top: 30px;">
      <el-card shadow="never">
        <div style="text-align: center;">
          <span style="font-size: 30px;color: #000000;font-weight: 500;">账号设置</span>
        </div>

        <div style="display: flex;justify-content: center;margin-top: 20px;">
          <div class="upload_bg_touxiang">
            <el-upload
                :action="action"
                :headers="headers"
                :file-list="fileList"
                :on-success="successUpload"
                :limit="1"
                :class="{hide_box: upload_btn}"
                :on-change="change"
                list-type="picture-card"
                :on-preview="Preview"
                :on-remove="handleRemove">
              <i class="el-icon-plus"></i>
              <!--<img style="margin-top: 60px;" src="@/assets/images/home/upload_anniu.png" alt="">-->
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </div>
        </div>

        <el-form ref="form" :model="form" label-width="100px">
          <el-row :gutter="15" style="margin-top: 20px;">
            <el-col :span="12">
              <el-form-item label="用户名">
                <el-input
                    clearable
                    v-model="form.realname"
                    placeholder="请输入用户名"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="手机号">
                <el-input
                    clearable
                    v-model="form.phone"
                    placeholder="请输入手机号"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="密码">
                <el-input
                    clearable
                    show-password
                    v-model="form.password"
                    placeholder="请输入旧密码"/>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="15">
            <el-col :span="12">
              <el-form-item label="确认密码">
                <el-input
                    clearable
                    show-password
                    v-model="form.affirm_password"
                    placeholder="请输入新密码"/>
              </el-form-item>
            </el-col>
          </el-row>

          <!--<el-row :gutter="15">-->
          <!--  <el-col :span="12">-->
          <!--    <el-form-item label="验证码">-->
          <!--      <el-input placeholder="请输入验证码" v-model="form.code">-->
          <!--        <template slot="append">-->
          <!--          <span style="color: #3F4157;cursor: pointer;" @click="getCode">-->
          <!--            {{!codeTime?'获取验证码':codeTime+'s'+'后重新获取'}}-->
          <!--          </span>-->
          <!--        </template>-->
          <!--      </el-input>-->
          <!--    </el-form-item>-->
          <!--  </el-col>-->
          <!--</el-row>-->

        </el-form>

      </el-card>
    </div>

    <!--按钮-->
    <div style="margin-top: 50px;text-align: center;">
      <el-button type="warning" style="width: 200px !important; margin-left: 20px;" @click="save">确定</el-button>
      <el-button style="width: 200px !important;" @click="returnPage">取消</el-button>
    </div>

  </div>
</template>

<script>
  // 获取配置
  import setting from '@/config/setting'
  import {getCookies} from '@/utils/cookies'
  import Config from '@/config'

  // 引入的接口
  import {account_setting, facilitatorinfo, update_admin} from '@/api/account'

  export default {
    data(){
      return{
        // 头像
        dialogImageUrl: '',
        dialogVisible: false,
        upload_btn: false, // 是否隐藏上传图片按钮

        form:{
          password:'',
          realname:'',
          phone:'',
          affirm_password:'',
          code:'',
        },

        codeText: '获取验证码',
        codeTime: 0,

        // 上传接口
        action: setting.apiBaseURL + 'common/upload/client',
        // 上传请求头部
        headers:{
          'Authori-Zation' : 'Bearer' + ' ' + getCookies(Config.tokenStoreName)
        },
        fileList:[],

      }
    },

    mounted() {
      // 获取平台管理基本信息接口
      this.getInfo();
    },

    methods:{
      //点击返回按钮
      returnPage(){
        this.$router.go(-1);
      },

      //获取平台管理员基本信息接口
      getInfo(){
        account_setting().then(res => {
          console.log(res);
          this.form = res.data;
          // this.form.password = '';
          this.fileList = [];
          if(res.data.avatar == ''){
            this.upload_btn = false;
            this.fileList = [];
          }else {
            let data = {
              url: res.data.avatar
            }
            this.fileList.push(data);
            this.upload_btn = true;
          }
        })
      },

      //头像
      handleRemove(file, fileList) {
        console.log(file, fileList);
        this.upload_btn = false;
      },
      Preview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      change(){
        // 上传成功后，隐藏上传按钮
        this.upload_btn = true;
      },
      //上传成功
      successUpload(file,fileList){
        console.log(file);
        console.log(fileList)
        // 上传成功后的列表
        this.form.avatar = file.data.urls;
      },

      // 点击获取验证码
      getCode() {
        //弹出提示框
        if(this.codeText == '获取验证码' && this.codeTime == 0){
          this.$message({
            message: '验证码已发送',
            type: 'success'
          });
        }
        if (this.codeTime > 0) {
          this.$message({
            message: '不能重复获取',
            type: 'warning'
          });
          return;
        } else {
          this.codeTime = 30
          let timer = setInterval(() => {
            this.codeTime--;
            if (this.codeTime < 1) {
              clearInterval(timer);
              this.codeTime = 0
            }
          }, 1000)
        }
      },


      //更新管理信息接口
      save(){
        if (this.form.password == '' || this.form.password == undefined){
          this.$message.error('密码不能为空');
        }else if(this.form.affirm_password == '' || this.form.affirm_password == undefined){
          this.$message.error('确认密码不能为空');
        }else if(this.form.affirm_password != this.form.password){
          this.$message.error('两次密码不一致');
        }else {
          let data = {
            avatar: this.form.avatar,
            realname: this.form.realname,
            phone: this.form.phone,
            password: this.form.password,
            affirm_password: this.form.affirm_password,
          }
          update_admin(data).then(res => {
            console.log(res);
            if(res.code == 200){
              this.$message.success(res.msg);
              this.getInfo();
            }else {
              this.$message.error(res.msg);
            }
          }).catch(err => {
            this.$message.error(res.msg);
          })
        }
      },


    },

  }
</script>

<style scoped lang="scss">
  .hide_box /deep/ .el-upload--picture-card {
    display: none;
  }
</style>
